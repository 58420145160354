




                    







const imageSlide = [
    
    {
     url : require('./Assets/Titanic_Render.png'),
     caption: '',
     link: 'https://www.roblox.com/games/refer?PlaceId=294790062'
     
    },
    {
        url : require('./Assets/Tsunami_Game_Commission.png'),
        caption: '',
        link: 'https://www.roblox.com/games/7993293100/Tsunami-Game'
     
    
    },
    {
        url : require('./Assets/Final4.png'),
        caption: '',
        link: 'https://www.roblox.com/games/4272052925/Survive-a-Plane-Crash'
     
       
    },
    {
        url : require('./Assets/Britannic_Thumbnail.png'),
        caption: '',
        link: 'https://www.roblox.com/games/939091785/Sinking-Ship-Roblox-Britannic'
     
    },
];
export default imageSlide