import React, {useState}  from "react";
import './Navbar.css'
import {  NavLink } from "react-router-dom";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <nav>
      
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
        
      </div>
      <ul className={menuOpen ? "open" : ""}>
    

        <li>
          <NavLink to="/HOME">HOME</NavLink>
        </li>
        


        <li>
          <NavLink  to="/GAMES">GAMES</NavLink>
        </li>

        <li>
          <NavLink  to="/SHOP">SHOP</NavLink>
        </li>

        <li>
          <NavLink  to="/ABOUT">ABOUT</NavLink>
        </li>

        
      </ul>
    </nav>
     
    )
}

export default Navbar