import React, { useState, useEffect } from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import imageSlide from "./data.js";

const divStyle = {
 
  backgroundSize: 'cover',

  height: '100vh',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  objectFit: 'cover'
};

const captionStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  color: '#fff',
  padding: '10px',
  textAlign: 'center'
};

function CarouselPage() {
  const [imageDataWithCaptions, setImageDataWithCaptions] = useState([]);
  
  useEffect(() => {
    fetchCaptionsFromAPI();
    const interval = setInterval(fetchCaptionsFromAPI, 60000); // Fetch API data every minute
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const fetchCaptionsFromAPI = () => {
    const apiEndpoints = [
      'https://games.roblox.com/v1/games?universeIds=114070244',
      'https://games.roblox.com/v1/games?universeIds=3085857678',
      'https://games.roblox.com/v1/games?universeIds=1388449456',
      'https://games.roblox.com/v1/games?universeIds=390818080'
    ];

    const fetchPromises = apiEndpoints.map(endpoint => {
      const apiUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(endpoint)}`;
      return fetch(apiUrl);
    });

    Promise.all(fetchPromises)
      .then(responses => Promise.all(responses.map(response => response.json())))
      .then(captionsArrays => {
        const mergedData = imageSlide.map((image, index) => {
          const responseData = JSON.parse(captionsArrays[index]?.contents || '{}');
          const data = responseData.data?.[0] || {};
          return {
            ...image,
            caption: `Playing: ${data.playing || 'N/A'} , Visits: ${data.visits || 'N/A'}`
          };
        });
        setImageDataWithCaptions(mergedData);
      });
  };

  return (
    <div className="slide-container">
      <Slide onChange={({ current, next }) => {
        console.log("Current slide index:", current);
      }}>
        {imageDataWithCaptions.map((image, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <a href={image.link} target="_blank" rel="noopener noreferrer">
              <div style={{ ...divStyle, backgroundImage: `url(${image.url})` }}>
              </div>
            </a>
            <div style={captionStyle}>{image.caption}</div>
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default CarouselPage;
