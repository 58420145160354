import React from "react";
import './Games.css'
import logo1 from './Assets/Titanic_Render.png'
import logo2 from './Assets/tsunami_game_tile.png'
import logo3 from './Assets/Final4.png'
import logo4 from './Assets/Britannic_Thumbnail.png'

export const Games = () => {
  return <div className="Games">
    <div className="txt1">GAMES</div> 


    <hr className="ruler"></hr>
    <a  className="heading1" href="https://www.roblox.com/games/294790062/Roblox-Titanic">Roblox Titanic</a>
    <div className="main-topic">
    
    <p>
    Aboard Roblox Titanic you can explore the ship's luxurious interior with fellow passengers! Reenact the infamous iceberg spotting. Roleplay as the ship sinks, rooms flood, lifeboats launch, funnels fall, and the ship splits in half. Sinking ship simulator. Based on Titanic, 1912.
    </p>
    
    <img src={logo1}  alt="" className="right-picture" /> 
    </div>


    <hr className="ruler1"></hr>
    <a  className="heading1" href="https://www.roblox.com/games/7993293100/Tsunami-Game">Tsunami Game</a>
    <div className="main-topic">
    
    <p>
    Get to the other side and survive wave after wave of tsunamis. A button anyone can press controls when a tsunami will spawn, and the longer that button is left alone, the larger the tsunami will be. As you cross the game, you will gain Risk and collect Points. Use earned Points to get survival gear items to better prepare for future crossings.
    </p>
    
    <img src={logo2}  alt="" className="right-picture" /> 
    </div>


    <hr className="ruler1"></hr>
    <a  className="heading1" href="https://www.roblox.com/games/4272052925/Survive-a-Plane-Crash">Survive a Plane Crash</a>
    <div className="main-topic">
    
    <p>
    Roblox's modern plane crash game has lift-off. Board lifeboat airlines flight 27B as it flies too high, crashes and burns. Turbulence on the way down bumps open the overhead bins with survival gear. With almost certain death remaining in the plane, you must find or fight for a gear and jump out in time!
    </p>
    
    <img src={logo3}  alt="" className="right-picture" /> 
    </div>


    <hr className="ruler1"></hr>
    <a  className="heading1" href="https://www.roblox.com/games/939091785/Sinking-Ship-Roblox-Britannic">Roblox Britannic</a>
    <div className="main-topic">
    
    <p>
    His Majesty's Hospital Ship: Britannic, on a journey to pickup wounded soldiers, hits a mine and comes to a halt, trying to beach nearby land. Help the evacuation efforts and survive the sinking ship! Sinking ship simulator. Based on HMHS Britannic, 1916.

    </p>
    
    <img src={logo4}  alt="" className="right-picture" /> 
    </div>
    <hr className="ruler1"></hr>
  </div>  


};

export default Games;