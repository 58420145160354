import React from "react";
import BasicSlider from '../BasicSlider/BasicSlider';


export const Home = () => {
  return <div>
  <BasicSlider/>

  </div>
  
  
};

export default Home;