import React, { useState } from "react";
import './Shop.css';
import logo1 from './Assets/lootbloc.png';
import logo2 from './Assets/Tsunami.png';
import logo3 from './Assets/download.png';
import data from "./data.js";
import data2 from "./data2.js";
import data1 from "./data1.js";

export const Shop = () => {
    const [products] = useState(data);
    const [value, setValue] = useState(0);
    const { mainImage } = products[value];

    const [products1] = useState(data1);
    const [value1, setValue1] = useState(0);
    const { mainImage: mainImage1 } = products1[value1];

    const [products2] = useState(data2);
    const [value2, setValue2] = useState(0);
    const { mainImage: mainImage2 } = products2[value2];

    return (
        <div>
            <div className="my-logo-container">
                <div className="my-logos">
                    <img src={logo1} alt=""/>
                </div>
              
                <div className="my-logos">
                    <img src={logo2} alt=""/>
                </div>
            </div>

            <section className="max-w-7xl">
                <article>
                    <img src={mainImage} alt="" className="h-96" />
                    <ul className="flex">
                        {products.map((data, index) => (
                            <li key={data.id} onClick={() => setValue(index)} className={index === value ? "border-2" : ""}>
                                <img src={data.thumbnail} alt="" className="w-20" />
                            </li>
                        ))}
                    </ul>
                </article>
                <article>
                    <h2>$31.18</h2>
                    <h1>TSUNAMI GAME - TSUNAMI WAVE PREMIUM TEE</h1>
                    <div>
                        <h3>
                            Take on the wave and dominate with the Tsunami Wave Premium Tee. Dare to push boundaries, invite the challenge, and embrace the thrill. Show off your bravery, fearlessness, and sport this premium tee with confidence!
                        </h3>
                    </div>
                    <h4><a href="https://lootbloc.com/products/tsunami-game-tsunami-wave-tee">🛒CLICK HERE TO PURCHASE🛒</a></h4>
                </article>
            </section>

            <section className="max-w-7xl">
                <article>
                    <img src={mainImage1} alt="" className="h-96" />
                    <ul className="flex">
                        {products1.map((data1, index) => (
                            <li key={data1.id} onClick={() => setValue1(index)} className={index === value1 ? "border-2" : ""}>
                                <img src={data1.thumbnail} alt="" className="w-20" />
                            </li>
                        ))}
                    </ul>
                </article>
                <article>
                    <h2>$26.38</h2>
                    <h1>TSUNAMI GAME - LOGO TEE</h1>
                    <div>
                        <h3>
                        Do you have what it takes to survive the big 🌊? Show off your survival skills with this epic Tsunami Game - Logo Tee! Brace yourself, because this tee is bringing on the heat!🔥
                        </h3>
                    </div>
                    <h4><a href="https://lootbloc.com/products/tsunami-game-logo-tee">🛒CLICK HERE TO PURCHASE🛒</a></h4>
                </article>
            </section>










            <section className="max-w-7xl">
                <article>
                    <img src={mainImage2} alt="" className="h-96" />
                    <ul className="flex">
                        {products2.map((data2, index) => (
                            <li key={data2.id} onClick={() => setValue2(index)} className={index === value1 ? "border-2" : ""}>
                                <img src={data2.thumbnail} alt="" className="w-20" />
                            </li>
                        ))}
                    </ul>
                </article>
                <article>
                    <h2>$10.79</h2>
                    <h1>TSUNAMI GAME - VINYL STICKER PACK SHEET (1PC)</h1>
                    <div>
                        <h3>
                        Do you have what it takes to survive the big 🌊? Show off your survival skills with these epic Tsunami Game Vinyl Stickers! Brace yourself, because these stickers are bringing on the heat!🔥
                        </h3>
                    </div>
                    <h4><a href="https://lootbloc.com/products/tsunami-game-vinyl-sticker-pack-sheet-1pc">🛒CLICK HERE TO PURCHASE🛒</a></h4>
                </article>
            </section>
        </div>
    );
};

export default Shop;
