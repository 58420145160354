import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../src/Assets/vvg_letters_only.png'
import {Route, Routes } from 'react-router-dom';
import {Home} from './Components/pages/Home'
import {About} from './Components/pages/About'
import {Games} from './Components/pages/Games'
import {Shop} from './Components/pages/Shop'
import Footer from './footer/footer';


function App() {
  return (
    <div>
       
       <img src={logo}  alt="" class="center" /> 
     <Navbar/> 
     <Routes>
      <Route path='/' element ={<Home/>}/>
      <Route path='/HOME' element ={<Home/>}/>
      <Route path='/GAMES' element ={<Games/>}/>
      <Route path='/ABOUT' element ={<About/>}/>
      <Route path='/SHOP' element ={<Shop/>}/>
     </Routes> 
     <Footer/>
     
  
    </div>
   
  );
}

export default App;
