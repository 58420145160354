const data = [
    {
        id: 1,
        mainImage: require('./Assets/prod1.png'),
        thumbnail: require('./Assets/prod1 thumb.png')
    },
    {
        id: 2,
        mainImage: require('./Assets/prod2.png'),
        thumbnail: require('./Assets/prod2 thumb.png')
    },
    {
        id: 3,
        mainImage: require('./Assets/prod3.png'),
        thumbnail: require('./Assets/prod3 thumb.png')
    },
    {
        id: 4,
        mainImage: require('./Assets/prod4.png'),
        thumbnail: require('./Assets/prod4 thumb.png')
    },
    {
        id: 5,
        mainImage: require('./Assets/prod5.png'),
        thumbnail: require('./Assets/prod5 thumb.png')
    }
];



export default data;


