const data1 = [
    {
        id: 1,
        mainImage: require('./Assets/tee1.png'),
        thumbnail: require('./Assets/tee1 thumb.png')
    },
    {
        id: 2,
        mainImage: require('./Assets/tee2.png'),
        thumbnail: require('./Assets/tee2 thumb.png')
    },
    {
        id: 3,
        mainImage: require('./Assets/tee3.png'),
        thumbnail: require('./Assets/tee3 thumb.png')
    },
    {
        id: 4,
        mainImage: require('./Assets/tee4.png'),
        thumbnail: require('./Assets/tee4 thumb.png')
    },
    {
        id: 5,
        mainImage: require('./Assets/tee5.png'),
        thumbnail: require('./Assets/tee5 thumb.png')
    }
];



export default data1;


