import React from "react";
import './About.css'
import logo1 from './Assets/vvg_valley.png'
import logo2 from './Assets/meet_amaze.png'
import logo3 from './Assets/meet_inyo.png'

export const About = () => {
  return <div>
    <div>
    <img src={logo1}  alt="" className="valley-picture" /> 
    <hr className="ruler11"></hr>
    <div className="topic">
      <p>
      Virtual Valley Games is a Roblox Game Development Studio started to bring intense simulation experiences to the virtual world.  VVG is a home grown independent studio earning player funded capital with the ultimate goal to create 10 perennial games.  VVG aims to theme games around nature vs man, history and philosophy.  
      </p>
    </div>
    <div className="txt11">Founders</div> 
    <hr className="ruler11"></hr>
    
    <div class="row">
  <div class="column">
    <img src={logo2} alt="Snow" className="meet1" />
    <p className="txt12"><u>TheAmazeman</u></p>
    <p className="txt13">Founder</p>
    
    <p>Joining Roblox in 2008, TheAmazeman has had a sustained long term drive in video game development, bringing 4 games to reality with programming, management, analytical and marketing skills.  Before Virtual Valley Games, TheAmazeman earned a Bachelors of Arts in Economics from the University of Arizona in 2017.
</p>
  </div>
  <div class="column">
    <img src={logo3} alt="Forest" className="meet2"/>
    <p className="txt12"><u>Inyo22</u></p>
    <p className="txt13">Co-Founder</p>
    <p>Joining Roblox in 2010, Inyo22 had a natural ability to understand the fun nature of Roblox games.  Inyo22 brought his ship building skills in front of TheAmazeman in 2011, later leading to the founding of Virtual Valley Games. Inyo22 now boasts several Roblox development skills including blender modeling, machinery physics, audio design and animations.  Before Virtual Valley Games, Inyo22 was a train engineer on a tourist line.  


</p>
  </div>
  
</div>


    </div>
    
  </div>  
};

export default About;