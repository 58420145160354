const data2 = [
    {
        id: 1,
        mainImage: require('./Assets/stick1.png'),
        thumbnail: require('./Assets/stick1 thumb.png')
    },
    {
        id: 2,
        mainImage: require('./Assets/stick2.png'),
        thumbnail: require('./Assets/stick2 thumb.png')
    },
    {
        id: 3,
        mainImage: require('./Assets/stick3.png'),
        thumbnail: require('./Assets/stick3 thumb.png')
    },
    {
        id: 4,
        mainImage: require('./Assets/stick4.png'),
        thumbnail: require('./Assets/stick4 thumb.png')
    },
    {
        id: 5,
        mainImage: require('./Assets/stick5.png'),
        thumbnail: require('./Assets/stick5 thumb.png')
    }
];



export default data2;


