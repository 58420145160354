import React from "react";
import './footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faDiscord,
    faTwitter,
    faInstagram, 
    faTiktok,
  } from "@fortawesome/free-brands-svg-icons";



const Footer=()=>{
    return(
        <div className="footer">
          
               
                   
                        <div className="sb__footer-links-div">
                         <h4>We Bring Intense Simulation Experience To The Virtual World</h4>
                        </div>
                        
                           
                            <div className="socialMedia"> 
                            <a href="https://www.tiktok.com/@vvgrblx"
        className="tiktok social">
        <FontAwesomeIcon icon={faTiktok} size="2x" />
      </a>
                            <a href="https://www.youtube.com/channel/UC7T0SjFHEJh4rRv3HqQKs4Q"
        className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://discord.gg/XNYY8X3gPU"
        className="facebook social">
        <FontAwesomeIcon icon={faDiscord} size="2x" />
      </a>
      <a href="https://twitter.com/vvgrblx" className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
      </a>
      <a href="https://www.instagram.com/vvgrblx/"
        className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
                            </div>
                      
                    <hr></hr>
                    
                        <div className="sb__footer-copyright">
                            <p>
                                @2024 Made By VVG. All Rights Reserved.
                            </p>

                        
                    </div>

                </div>
            
        
    )
}

export default Footer